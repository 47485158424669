import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, Events, NavController, ModalController, NavParams } from '@ionic/angular';

import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-product-vendor-modal',
  templateUrl: './product-vendor-modal.page.html',
  styleUrls: ['./product-vendor-modal.page.scss'],
})
export class ProductVendorModalPage implements OnInit {
  @Input() pageTitle: string;
  products = [];
  productStatus: boolean = false;
  searchProd: any;
  searchItems = [];
  searchBy: any = 'sku';

  qrCode: any;
  qrItem: any;

  vendors: any;
  vendorStatus: boolean = false;

  limit = 15;
  offset = 0;

  constructor(private route: ActivatedRoute, public events: Events, private navCtrl: NavController,
    public router: Router, public loadingCtrl: LoadingController, private server: ServerService,
    public global: GlobalVariable, public modalController: ModalController, public navParams: NavParams,
    private barcodeScanner: BarcodeScanner) {

    navParams.get('pageTitle');
  }

  ngOnInit() {
    if (this.pageTitle == 'Product') {
      this.getProducts();
    } else {
      this.getVendors();
    }
  }

  doInfinite(infiniteScroll) {
    console.log('Begin async operation');

    setTimeout(() => {
      this.getProducts();
      console.log('Async operation has ended');
      infiniteScroll.target.complete();
    }, 500);
  }

  //Search code
  async selectedSearch($event) {
    this.searchBy = $event.target.value;
    this.searchProd = "";
    this.searchItems = [];
    console.log("Search by ", this.searchBy);
  }

  async setFilteredItems(findBy) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Product ", this.searchProd);
    loading.dismiss();
    if (this.searchProd != "") {
      loading.dismiss();
      this.filterItems(this.searchProd, findBy);
      console.log("After Search ", this.searchItems);
    } else if (this.searchProd == "") {
      this.searchItems = [];
    }
  }

  filterItems(searchTerm, findBy) {
    if (findBy == 'name') {
      // return this.products.filter((item) => {
      //   return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts(searchTerm, '', '');
    }

    if (findBy == 'sku') {
      // return this.products.filter((item) => {
      //   return item.sku.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts('', searchTerm, '');
    }
  }

  async searchProducts(name, sku, catName) {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.searchProduct(name, sku, catName);
    response.subscribe(data => {
      loading.dismiss();
      console.log("Data res: ", data);

      if (data.success != false) {
        console.log("Data res: ", data.data);
        for (let x = 0; x < data.data.length; x++) {
          this.searchItems.push(data.data[x]);
        }
        for (var i = 0; i <= this.searchItems.length; i++) {
          this.searchItems[i].Qty = 1;
        }
      } else {
        this.global.presentToast(data.message);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  emptySearch() {
    this.searchItems = [];
  }
  //Search End

  increase(item) {
    if (item.Qty === NaN) {
      item.Qty = 0;
      item.Qty++;
    } else {
      item.Qty++;
      this.global.createPurArray.forEach(element => {
        if (Number(element.id) == Number(item.id)) {
          if (item.Qty == 0) {
            // element.Qty++;
          } else {
            element.Qty++;
          }
        }
      });
    }
  }

  decrease(item) {
    if (item.Qty < 0) {
      item.Qty = 0;
    }

    if (item.Qty != 0) {
      item.Qty--;
      this.global.createPurArray.forEach(element => {
        if (Number(element.id) == Number(item.id)) {
          if (item.Qty == 0) {
            // element.Qty++;
          } else {
            element.Qty--;
          }
        }
      });
    }
  }

  addQty(item) {
    if (item.Qty < 0 || item.Qty == '' || item.Qty == null) {
      item.Qty = 0;
    }
    console.log("Changing QTY ", item)
  }

  async addToArray(item) {
    let idMatch = false;
    if (this.global.createPurArray.length == 0) {
      let cost = Number(item.cost) * Number(item.Qty);
      this.global.createPurArray.push({ 'id': item.id, 'name': item.name, 'cost': cost, 'Qty': item.Qty });
    } else {
      this.global.createPurArray.forEach(element => {
        if (Number(element.id) == Number(item.id)) {
          idMatch = true;
        }
      });

      if (idMatch) {
        console.log("Already present");
        this.global.createPurArray.forEach(element => {
          if (Number(element.id) == Number(item.id)) {
            if (item.Qty == 0) {
              // element.Qty++;
            } else {
              element.Qty++;
              item.Qty++;
              element.cost = Number(item.cost) * Number(element.Qty);
            }
          }
        });
      } else {
        let cost = Number(item.cost) * Number(item.Qty);
        this.global.createPurArray.push({ 'id': item.id, 'name': item.name, 'cost': cost, 'Qty': item.Qty });
      }
    }
    console.log(this.global.createPurArray)
    this.close();
  }

  async addVendorToArray(item) {
    if (this.global.createVenArray.length == 0) {
      this.global.createVenArray.push({ 'id': item.id, 'name': item.name });
    }

    console.log(this.global.createVenArray);
    this.close();
  }

  async getProducts() {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.getProducts(this.offset, this.limit);
    response.subscribe(data => {
      loading.dismiss();

      if (data.success != false) {
        this.productStatus = data.success;
        console.log("Data res: ", data.data);
        // this.products = data.data;
        for (let x = 0; x < data.data.length; x++) {
          this.products.push(data.data[x]);
        }
        this.offset = this.offset + this.limit;
        for (var i = 0; i <= this.products.length; i++) {
          this.products[i].Qty = 1;
        }
      } else {
        this.global.presentToast(this.global.toast_wentWrong);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  async getVendors() {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.getVendors();
    response.subscribe(data => {
      loading.dismiss();
      console.log("Data res: ", data);

      if (data.success != false) {
        this.vendorStatus = data.success;
        console.log("Data res: ", data.data);
        this.vendors = data.data;
      } else {
        this.global.presentToast(this.global.toast_wentWrong);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  //barcode to list
  scanQR() {
    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Place a barcode to scan product sku',
      resultDisplayDuration: 500,
      formats: 'QR_CODE,PDF_417,RSS_EXPANDED,EAN-13',
      orientation: 'portrait',
      disableSuccessBeep: false
    };

    this.barcodeScanner.scan(options).then(barcodeData => {
      console.log('Barcode data', barcodeData);
      this.global.presentToast(barcodeData.text)
      this.qrCode = barcodeData.text;
      this.qrItem = this.filterItems(this.qrCode, 'sku');
      if(this.qrItem.length == 0){
        this.global.presentToast("No product found for " + this.qrCode)
      }else{
        this.addToArray(this.qrItem[0]);
      }
    }).catch(err => {
      console.log('Error', err);
      // this.global.presentToast("Invalid barcode")
    });
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
