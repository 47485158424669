import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { LoadingController, Events, NavController, ModalController, NavParams } from '@ionic/angular';

import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-message-to-user',
  templateUrl: './message-to-user.page.html',
  styleUrls: ['./message-to-user.page.scss'],
})
export class MessageToUserPage implements OnInit {
  @Input() pageTitle: string;
  messageForm: FormGroup;
  emailForm: FormGroup;
  submitAttempt: boolean = false;

  constructor(public formBuilder: FormBuilder, public router: Router, public loadingCtrl: LoadingController, private server: ServerService,
    public global: GlobalVariable, public modalController: ModalController, public navParams: NavParams) {

    navParams.get('pageTitle');
    this.messageForm = formBuilder.group({
      msg_text: ['', Validators.compose([Validators.maxLength(160), Validators.required])]
    });
    
    this.emailForm = formBuilder.group({
      email_subject: ['', Validators.compose([Validators.maxLength(80), Validators.required])],
      email_body: ['', Validators.compose([Validators.maxLength(160), Validators.required])]
    });
  }

  ngOnInit() {

  }

  sendMessage(messageData: any) {
    if (!this.messageForm.valid) {
      this.submitAttempt = true;
    } else {
      this.global.message_to_users = messageData.msg_text;
      this.close();
    }
  }

  sendEmailMessage(emailData: any) {
    if (!this.emailForm.valid) {
      this.submitAttempt = true;
    } else {
      this.global.message_to_users = emailData.email_body;
      this.global.emailSubject = emailData.email_subject;
      this.close();
    }
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
