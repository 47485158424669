import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'list', loadChildren: () => import('./list/list.module').then(m => m.ListPageModule) },
  { path: 'inventory', loadChildren: './inventory/inventory.module#InventoryPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'products', loadChildren: './products/products.module#ProductsPageModule' },
  { path: 'add-update-category', loadChildren: './add-update-category/add-update-category.module#AddUpdateCategoryPageModule' },
  { path: 'categories', loadChildren: './categories/categories.module#CategoriesPageModule' },
  { path: 'add-update-product', loadChildren: './add-update-product/add-update-product.module#AddUpdateProductPageModule' },
  { path: 'vendors', loadChildren: './vendors/vendors.module#VendorsPageModule' },
  { path: 'add-update-vendor', loadChildren: './add-update-vendor/add-update-vendor.module#AddUpdateVendorPageModule' },
  { path: 'purchases', loadChildren: './purchases/purchases.module#PurchasesPageModule' },
  { path: 'add-update-purchases', loadChildren: './add-update-purchases/add-update-purchases.module#AddUpdatePurchasesPageModule' },
  { path: 'product-vendor-modal', loadChildren: './product-vendor-modal/product-vendor-modal.module#ProductVendorModalPageModule' },
  { path: 'invoices', loadChildren: './invoices/invoices.module#InvoicesPageModule' },
  { path: 'product-customer-modal', loadChildren: './product-customer-modal/product-customer-modal.module#ProductCustomerModalPageModule' },
  { path: 'add-update-invoice', loadChildren: './add-update-invoice/add-update-invoice.module#AddUpdateInvoicePageModule' },
  { path: 'printer-modal', loadChildren: './printer-modal/printer-modal.module#PrinterModalPageModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' },
  { path: 'reporting', loadChildren: './reporting/reporting.module#ReportingPageModule' },
  { path: 'reporting-detail', loadChildren: './reporting-detail/reporting-detail.module#ReportingDetailPageModule' },
  { path: 'customers', loadChildren: './customers/customers.module#CustomersPageModule' },
  { path: 'add-update-customer', loadChildren: './add-update-customer/add-update-customer.module#AddUpdateCustomerPageModule' },
  { path: 'promotions', loadChildren: './promotions/promotions.module#PromotionsPageModule' },
  { path: 'sale-products-modal', loadChildren: './sale-products-modal/sale-products-modal.module#SaleProductsModalPageModule' },
  { path: 'add-update-promotions', loadChildren: './add-update-promotions/add-update-promotions.module#AddUpdatePromotionsPageModule' },
  { path: 'drafts', loadChildren: './drafts/drafts.module#DraftsPageModule' },
  { path: 'summary', loadChildren: './summary/summary.module#SummaryPageModule' },
  { path: 'printer-setting', loadChildren: './printer-setting/printer-setting.module#PrinterSettingPageModule' },
  { path: 'taxes', loadChildren: './taxes/taxes.module#TaxesPageModule' },
  { path: 'add-update-tax', loadChildren: './add-update-tax/add-update-tax.module#AddUpdateTaxPageModule' },
  { path: 'help-support', loadChildren: './help-support/help-support.module#HelpSupportPageModule' },
  { path: 'pos-banners', loadChildren: './pos-banners/pos-banners.module#PosBannersPageModule' },
  { path: 'add-update-pos-banner', loadChildren: './add-update-pos-banner/add-update-pos-banner.module#AddUpdatePosBannerPageModule' },
  { path: 'access-levels', loadChildren: './access-levels/access-levels.module#AccessLevelsPageModule' },
  { path: 'add-update-access-levels', loadChildren: './add-update-access-levels/add-update-access-levels.module#AddUpdateAccessLevelsPageModule' },
  { path: 'staff', loadChildren: './staff/staff.module#StaffPageModule' },
  { path: 'add-update-staff', loadChildren: './add-update-staff/add-update-staff.module#AddUpdateStaffPageModule' },
  { path: 'business-profile', loadChildren: './business-profile/business-profile.module#BusinessProfilePageModule' },
  { path: 'product-promotion', loadChildren: './product-promotion/product-promotion.module#ProductPromotionPageModule' },
  { path: 'sms-promotion', loadChildren: './sms-promotion/sms-promotion.module#SmsPromotionPageModule' },
  { path: 'message-to-user', loadChildren: './message-to-user/message-to-user.module#MessageToUserPageModule' },
  { path: 'email-promotion', loadChildren: './email-promotion/email-promotion.module#EmailPromotionPageModule' },
  { path: 'push-notify-promotion', loadChildren: './push-notify-promotion/push-notify-promotion.module#PushNotifyPromotionPageModule' },
  { path: 'giftcard-design', loadChildren: './giftcard-design/giftcard-design.module#GiftcardDesignPageModule' },
  { path: 'giftcard-stats', loadChildren: './giftcard-stats/giftcard-stats.module#GiftcardStatsPageModule' },
  { path: 'add-update-giftcard-design', loadChildren: './add-update-giftcard-design/add-update-giftcard-design.module#AddUpdateGiftcardDesignPageModule' },
  { path: 'giftcard-design-banners', loadChildren: './giftcard-design-banners/giftcard-design-banners.module#GiftcardDesignBannersPageModule' },
  { path: 'giftcard-design-image-upload', loadChildren: './giftcard-design-image-upload/giftcard-design-image-upload.module#GiftcardDesignImageUploadPageModule' },
  { path: 'customer-reviews', loadChildren: './customer-reviews/customer-reviews.module#CustomerReviewsPageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'app-settings', loadChildren: './app-settings/app-settings.module#AppSettingsPageModule' },
  { path: 'app-setting-img-upload', loadChildren: './app-setting-img-upload/app-setting-img-upload.module#AppSettingImgUploadPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash:true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
