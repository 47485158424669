import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { LoadingController, Events, NavController, ModalController, NavParams } from '@ionic/angular';

import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.page.html',
  styleUrls: ['./summary.page.scss'],
})
export class SummaryPage implements OnInit {
  @Input() pageTitle: string;
  @Input() id: string;
  @Input() date: string;
  @Input() time: string;
  @Input() total: string;
  @Input() grandTotal: string;
  @Input() tax: string;

  showGroup: any;
  paymentType: any = '';
  currentDate: any = new Date().toISOString();

  cardForm: FormGroup;
  card_expire: any;
  card_cvv: any;
  card_number: any;
  amount: any;
  transectionType: any = '';
  submitAttempt: boolean = false;

  constructor(public formBuilder: FormBuilder, public loadingCtrl: LoadingController, private server: ServerService,
    public navParams: NavParams, public global: GlobalVariable, public modalController: ModalController) {
    navParams.get('pageTitle');
    navParams.get('id');
    navParams.get('date');
    navParams.get('time');
    navParams.get('total');
    navParams.get('grandTotal');
    navParams.get('tax');

    this.cardForm = formBuilder.group({
      card_number: ['', Validators.compose([Validators.maxLength(16), Validators.required])],
      card_cvv: ['', Validators.compose([Validators.maxLength(4), Validators.required])],
      card_expire: ['', Validators.compose([Validators.required])],
      transectionType: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
  }

  async selectedType($event) {
    this.paymentType = $event.target.value;
    if (this.paymentType == 'cash') {
      this.amount = this.total;
    }
    console.log("Type ", this.paymentType);
  }

  selectedTransType($event) {
    this.transectionType = $event.target.value;
    if (this.transectionType == 'full') {
      this.amount = this.total;
    }
    console.log("Transection Type ", this.transectionType);
  }

  toggleGroup(num) {
    if (this.isGroupShown(num)) {
      this.showGroup = null;
    } else {
      this.showGroup = num;
    }
  }

  isGroupShown(num) {
    return this.showGroup == num;
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  formCheck(cardData: any) {
    if (!this.cardForm.valid) {
      this.submitAttempt = true;
      this.global.presentToast(this.global.toast_formValues);
    } else {
      console.log("Card data ", cardData);
      if (Number(this.amount) > Number(this.total) || this.amount == '') {
        this.submitAttempt = true;
        this.global.presentToast('Entered amount is greater then previous');
      } else {
        this.submitAttempt = false;
        this.proceed(cardData);
      }
    } 
  }

  amountCheck(e) {
    console.log("Entered Amount ", this.amount, this.total);
    if (Number(this.amount) > Number(this.total) || this.amount == '') {
      this.submitAttempt = true;
      this.global.presentToast('Entered amount is greater then previous');
    } else {
      this.submitAttempt = false;
    }
  }

  dateSelected(ev: any) {
    this.card_expire = this.formatDate(ev.detail.value);
  }

  formatDate(value) {
    var date = new Date(value);
    var year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = Number('0' + dt);
    }
    if (month < 10) {
      month = Number('0' + month);
    }
    var temp;
    return temp = year + '-' + month;
  }

  async proceed(cardData?) {
    var card_info;

    if (this.paymentType == 'card' && this.cardForm.valid) {
      card_info = [{
        card_number: cardData.card_number,
        card_cvv: cardData.card_cvv,
        card_expiry: this.card_expire,
        transection_type: cardData.transectionType
      }];
    } else {
      card_info = [{
        card_number: '',
        card_cvv: '',
        card_expiry: '',
        transection_type: ''
      }];
    }
    console.log("Card Info ", card_info);
    if (this.paymentType != '') {
      this.total = this.amount;
      const loading = await this.loadingCtrl.create({
        message: this.global.loader_plzWait,
        duration: 2000
      });
      await loading.present();

      let response = this.server.updateTransectionDetail(this.id, this.paymentType, this.total, card_info);
      response.subscribe(data => {
        loading.dismiss();
        console.log("Payment Update res: ", data);
        if (data.success != false) {
          this.global.presentToast(data.message);
          this.close();
        } else {
          this.global.presentToast(this.global.toast_wentWrong);
        }
      }, error => {
        loading.dismiss();
        console.log(error);
        this.global.presentToast(this.global.toast_checkInternet);
      });
    } else {
      this.global.presentToast('Payment type is not selected');
    }
  }

}
