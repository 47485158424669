import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, NavController, MenuController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { GlobalVariable } from '../app/global';
import { ServerService } from '../app/server.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // public appPages = [
  //   {
  //     title: 'Home',
  //     url: '/home',
  //     icon: 'home'
  //   },
  //   {
  //     title: 'List',
  //     url: '/list',
  //     icon: 'list'
  //   },
  //   {
  //     title: 'Logout',
  //     url: '/list',
  //     icon: 'list'
  //   }
  // ];

  constructor(
    private platform: Platform, public router: Router,
    private splashScreen: SplashScreen, public global: GlobalVariable,
    private statusBar: StatusBar, private nativeStorage: NativeStorage,
    private menu: MenuController, public navCtrl: NavController,
    public loadingCtrl: LoadingController, private server: ServerService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(res => {
      this.global.platformType = this.platform.is("mobileweb")
      if (this.platform.is("mobileweb")) {
        this.global.platformType = "mobileweb";
      } else {
        
      }
      console.log('platform ready ', this.global.platformType);
    });

    this.platform.ready().then(() => {
      this.nativeStorage.getItem('draft_purchase').then(data => {
        this.global.draftPurArray = data.purchaseArray;
        console.log('Draft storage', this.global.draftPurArray);
      }, error => {
        console.log('Draft storage', error)
      }).catch(err => { console.log(err) });

      this.nativeStorage.getItem('draft_invoice').then(data => {
        this.global.draftInvoiceArray = data.invoiceArray;
        console.log('Draft storage', this.global.draftInvoiceArray);
      }, error => {
        console.log('Draft storage', error)
      }).catch(err => { console.log(err) });

      this.nativeStorage.getItem('printer').then(data => {
        this.global.printerDevice = data;
        this.global.noPrinter = false;
        console.log('printer storage', this.global.printerDevice);
      }, error => {
        console.log('printer storage', error)
        this.global.noPrinter = true;
      }).catch(err => { console.log(err) });

      this.nativeStorage.getItem('user').then(data => {
        console.log("User -> ", data);
        this.global.udid = data.udid;
        this.global.name = data.name;
        this.global.logo = data.logo;
        this.global.username = data.username;
        this.global.password = data.password;

        this.navCtrl.navigateRoot('home');
        this.splashScreen.hide();
      }, error => {
        //we don't have the user data so we will ask him to log in
        console.log('App component Login called', error)

        this.navCtrl.navigateRoot('login');
        this.splashScreen.hide();
      }).catch(err => { console.log(err) });

      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000');
      this.statusBar.styleLightContent();

    });
  }

  openCustom(page) {
    this.menu.close();
    this.navCtrl.navigateForward(page);
  }

  async logout() {
    console.log('App component Logout')
    let loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    let res_signOut = this.server.logout(this.global.udid, '');
    res_signOut.subscribe(res => {
      loading.dismiss();
      console.log("Logout Res ", res);
      if (res.status != false) {

        this.global.udid = "";
        this.global.username = "";
        this.global.name = "";
        this.global.logo = "";
        this.global.password = "";

        //for ionic serve
        // this.router.navigate(['login']);

        this.nativeStorage.remove('user').then(() => {
          console.log('Native Storage then worked');
          this.global.presentToast(res.message);
          // this.router.navigate(['login']);
          this.navCtrl.navigateRoot('login');
        })
          .catch((err) => { console.log('Native Storage Error -> ', err) });
      } else {
        this.global.presentToast("Invalid Email or Password");
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast("Something went wrong check your internet connection.");
    });

  }
}
