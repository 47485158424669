import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController, ModalController, NavParams } from '@ionic/angular';

import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-sale-products-modal',
  templateUrl: './sale-products-modal.page.html',
  styleUrls: ['./sale-products-modal.page.scss'],
})
export class SaleProductsModalPage implements OnInit {
  products = [];
  productStatus: boolean = false;
  searchProd: any;
  searchItems = [];
  searchBy: any = 'sku';

  qrCode: any;
  qrItem: any;

  limit = 15;
  offset = 0;

  constructor(private server: ServerService, public global: GlobalVariable, private barcodeScanner: BarcodeScanner,
    public loadingCtrl: LoadingController, public modalController: ModalController) {

  }

  ngOnInit() {
    this.getProducts();
  }

  doInfinite(infiniteScroll) {
    console.log('Begin async operation');

    setTimeout(() => {
      this.getProducts();
      console.log('Async operation has ended');
      infiniteScroll.target.complete();
    }, 500);
  }

  doRefresh(event) {
    console.log('Begin async operation');

    setTimeout(() => {
      console.log('Async operation has ended');
      this.limit = 15;
      this.offset = 0;
      this.products = [];
      this.getProducts();
      event.target.complete();
    }, 2000);
  }

  //Search code
  async selectedSearch($event) {
    this.searchBy = $event.target.value;
    this.searchProd = "";
    this.searchItems = [];
    console.log("Search by ", this.searchBy);
  }

  async setFilteredItems(findBy) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Product ", this.searchProd);
    loading.dismiss();
    if (this.searchProd != "") {
      loading.dismiss();
      this.filterItems(this.searchProd, findBy);
      console.log("After Search ", this.searchItems);
    } else if (this.searchProd == "") {
      this.searchItems = [];
    }
  }

  filterItems(searchTerm, findBy) {
    if (findBy == 'name') {
      // return this.products.filter((item) => {
      //   return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts(searchTerm, '', '');
    }

    if (findBy == 'sku') {
      // return this.products.filter((item) => {
      //   return item.sku.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts('', searchTerm, '');
    }

    if (findBy == 'category_name') {
      // return this.products.filter((item) => {
      //   return item.category_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts('', '', searchTerm);
    }
  }

  async searchProducts(name, sku, catName) {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.searchProduct(name, sku, catName);
    response.subscribe(data => {
      loading.dismiss();
      console.log("Data res: ", data);

      if (data.success != false) {
        console.log("Data res: ", data.data);
        for (let x = 0; x < data.data.length; x++) {
          this.searchItems.push(data.data[x]);
        }
        // for (var i = 0; i <= this.searchItems.length; i++) {
        //   this.searchItems[i].Qty = 1;
        // }
      } else {
        this.global.presentToast(data.message);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  emptySearch() {
    this.searchItems = [];
  }
  //Search End

  async getProducts() {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.getProducts(this.offset, this.limit);
    response.subscribe(data => {
      loading.dismiss();

      if (data.success != false) {
        this.productStatus = data.success;
        console.log("Data res: ", data.data);
        // this.products = data.data;
        for (let x = 0; x < data.data.length; x++) {
          this.products.push(data.data[x]);
        }
        this.offset = this.offset + this.limit;
        // for (var i = 0; i <= this.products.length; i++) {
        //   this.products[i].Qty = 1;
        // }
      } else {
        this.global.presentToast(this.global.toast_wentWrong);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  async addToArray(item) {
    let idMatch = false;
    if (this.global.onSaleProducts.length == 0) {
      this.global.onSaleProducts.push({ 'id': item.id, 'name': item.name, 'color': item.color, 'category_id': item.category_id, 'category_name': item.category_name });
    } else {
      this.global.onSaleProducts.forEach(element => {
        if (Number(element.id) == Number(item.id)) {
          idMatch = true;
        }
      });

      if (idMatch) {
        console.log("Already present");
      } else {
        this.global.onSaleProducts.push({ 'id': item.id, 'name': item.name, 'color': item.color, 'category_id': item.category_id, 'category_name': item.category_name });
      }
    }
    console.log(this.global.onSaleProducts)
    this.close();
  }

  //barcode to list
  scanQR() {
    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Place a barcode to scan product sku',
      resultDisplayDuration: 500,
      formats: 'QR_CODE,PDF_417,RSS_EXPANDED,EAN-13',
      orientation: 'portrait',
      disableSuccessBeep: false
    };

    this.barcodeScanner.scan(options).then(barcodeData => {
      console.log('Barcode data', barcodeData);
      this.global.presentToast(barcodeData.text)
      this.qrCode = barcodeData.text;
      this.qrItem = this.filterItems(this.qrCode, 'sku');
      if (this.qrItem.length == 0) {
        this.global.presentToast("No product found for " + this.qrCode)
      } else {
        this.addToArray(this.qrItem[0]);
      }
    }).catch(err => {
      console.log('Error', err);
      // this.global.presentToast("Invalid barcode")
    });
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
