import { NgModule, enableProdMode  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { OrderModule } from 'ngx-order-pipe';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { HttpClient,  HttpClientModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ColorPickerModule } from 'ngx-color-picker';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { QuillModule } from 'ngx-quill';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { ServerService } from '../app/server.service';
import { GlobalVariable } from '../app/global';

import { ProductVendorModalPage } from '../app/product-vendor-modal/product-vendor-modal.page';
import { ProductCustomerModalPage } from '../app/product-customer-modal/product-customer-modal.page';
import { PrinterModalPage } from '../app/printer-modal/printer-modal.page';
import { SummaryPage } from '../app/summary/summary.page';
import { SaleProductsModalPage } from '../app/sale-products-modal/sale-products-modal.page';
import { MessageToUserPage } from '../app/message-to-user/message-to-user.page';
import { RevenueComponent } from '../app/components/revenue/revenue.component';

@NgModule({
  declarations: [ AppComponent, ProductVendorModalPage, SummaryPage, ProductCustomerModalPage, PrinterModalPage, SaleProductsModalPage, MessageToUserPage ],
  entryComponents: [ ProductVendorModalPage, SummaryPage, ProductCustomerModalPage, PrinterModalPage, SaleProductsModalPage, MessageToUserPage ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule, 
    ColorPickerModule,
    HttpModule,
    OrderModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        syntax: true
      }
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeStorage,
    HttpClient,
    Camera,
    BluetoothSerial,
    FileOpener,
    FileTransfer,
    BarcodeScanner,
    InAppBrowser,
    ServerService,
    GlobalVariable,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
enableProdMode();
