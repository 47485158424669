import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, AlertController, Events, NavController, ModalController, NavParams } from '@ionic/angular';

import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

import { PrinterService } from '../../app/printer.service';
import { commands } from '../../app/printer-commands';

@Component({
  selector: 'app-printer-modal',
  templateUrl: './printer-modal.page.html',
  styleUrls: ['./printer-modal.page.scss'],
})
export class PrinterModalPage implements OnInit {
  @Input() pageTitle: string;
  @Input() purchase: any;
  @Input() invoice: any;

  receipt: any;
  date: any;
  tax: any;

  ProductsTotal: any;
  CartTax: any;
  DiscountDisplay: any;
  Total: any;
  CartTaxDisplay: any;
  TotalDisplay: any;

  printTransaction: boolean = false;

  constructor(private route: ActivatedRoute, public events: Events, private navCtrl: NavController,
    public router: Router, public loadingCtrl: LoadingController, private server: ServerService,
    public global: GlobalVariable, public modalController: ModalController, public navParams: NavParams,
    private printer: PrinterService, public alertCtrl: AlertController) {

    this.pageTitle = navParams.get('pageTitle');
    if (this.pageTitle == 'Purchase') {
      this.purchase = navParams.get('purchase');
    }

    if (this.pageTitle == 'Invoice') {
      this.invoice = navParams.get('invoice');
      this.total('invoice');
    }

    console.log("Selected purchase ", this.purchase);
    console.log("Selected invoice ", this.invoice);
  }

  ngOnInit() {
    const now = new Date();
    var month = Number(now.getMonth()) + 1;
    this.date = '' + now.getDate() + '/' + month + '/' + now.getFullYear() + '';
  }

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  transactionCheck($event) {
    if ($event.detail.checked) {
      this.printTransaction = true;
    } else {
      this.printTransaction = false;
    }
    console.log('Print Transaction', this.printTransaction);
  }

  //Printer code
  noSpecialChars(string) {
    var translate = {
      à: 'a',
      á: 'a',
      â: 'a',
      ã: 'a',
      ä: 'a',
      å: 'a',
      æ: 'a',
      ç: 'c',
      è: 'e',
      é: 'e',
      ê: 'e',
      ë: 'e',
      ì: 'i',
      í: 'i',
      î: 'i',
      ï: 'i',
      ð: 'd',
      ñ: 'n',
      ò: 'o',
      ó: 'o',
      ô: 'o',
      õ: 'o',
      ö: 'o',
      ø: 'o',
      ù: 'u',
      ú: 'u',
      û: 'u',
      ü: 'u',
      ý: 'y',
      þ: 'b',
      ÿ: 'y',
      ŕ: 'r',
      À: 'A',
      Á: 'A',
      Â: 'A',
      Ã: 'A',
      Ä: 'A',
      Å: 'A',
      Æ: 'A',
      Ç: 'C',
      È: 'E',
      É: 'E',
      Ê: 'E',
      Ë: 'E',
      Ì: 'I',
      Í: 'I',
      Î: 'I',
      Ï: 'I',
      Ð: 'D',
      Ñ: 'N',
      Ò: 'O',
      Ó: 'O',
      Ô: 'O',
      Õ: 'O',
      Ö: 'O',
      Ø: 'O',
      Ù: 'U',
      Ú: 'U',
      Û: 'U',
      Ü: 'U',
      Ý: 'Y',
      Þ: 'B',
      Ÿ: 'Y',
      Ŕ: 'R',
    },
      translate_re = /[àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßàáâãäåæçèéêëìíîïðñòóôõöøùúûýýþÿŕŕÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÝÝÞŸŔŔ]/gim;
    return string.replace(translate_re, function (match) {
      return translate[match];
    });
  }

  async print(device, data) {
    console.log('Device mac: ', device);
    console.log('Data: ', data);
    const loading = await this.loadingCtrl.create({
      message: 'Printing...',
    });
    await loading.present();

    this.printer.connectBluetooth(device).subscribe(
      (status) => {
        console.log(status);
        this.printer
          .printData(this.noSpecialChars(data))
          .then((printStatus) => {
            console.log(printStatus);
            loading.dismiss();
            this.global.presentToast(this.global.printSuccess);
            this.printer.disconnectBluetooth();
          })
          .catch((error) => {
            console.log(error);
            loading.dismiss();
            this.global.presentToast(this.global.errorPrinting);
          });
      },
      (error) => {
        console.log(error);
        loading.dismiss();
        this.global.presentToast(this.global.errorConnecting);
      },
    );
  }

  prepareToPrint() {
    var tab = '    ';

    // u can remove this when generate the receipt using another method
    if (this.pageTitle == 'Purchase') {
      let receipt = '';
      receipt += commands.HARDWARE.HW_INIT;
      receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += this.global.name.toUpperCase();
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_NORMAL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += commands.EOL;
      receipt += "Vendor ID:" + this.purchase.vendor_id;
      receipt += commands.EOL;
      receipt += "Vendor name:" + this.purchase.vendor_name;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += "Date: " + this.date;
      receipt += commands.EOL;
      receipt += "Purchase created:" + this.purchase.datetime;
      receipt += commands.EOL;
      // receipt += tab + tab + tab + tab;
      // receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
      // receipt += "Time: ";
      // receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
      receipt += "Item Name" + tab + '' + tab + '' + tab + '' + tab + '' + tab + "Qty" + tab + tab + "Amount"
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.EOL;
      this.purchase.purchase_items.forEach(items => {
        var tabs = '';
        if (items.product_name.length < 25) {
          for (var i = 0; i <= (25 - items.product_name.length); i++) {
            tabs += ' ';
          }
        } else {
          tabs = commands.EOL + tab + '' + tab + '' + tab + '' + tab;
        }
        receipt += items.product_name + tabs + tab + items.qty + tab + tab + items.cost;
        receipt += commands.EOL;
      });
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR_58MM;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
      receipt += "Total:$" + this.purchase.total_price;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_NORMAL;
      receipt += commands.HORIZONTAL_LINE.HR_58MM;
      receipt += commands.EOL;
      //secure space on footer
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;

      this.print(this.global.printerDevice.address, receipt);
    }

    if (this.pageTitle == 'Invoice') {
      let receipt = '';
      receipt += commands.HARDWARE.HW_INIT;
      receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += this.global.name.toUpperCase();
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_NORMAL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += commands.EOL;
      receipt += "Order#:" + this.invoice.order_number;
      receipt += commands.EOL;
      receipt += "Payment method:" + this.invoice.payment_method;
      receipt += commands.EOL;
      receipt += "Order via:" + this.invoice.order_via;
      receipt += commands.EOL;
      receipt += "Status:" + this.invoice.status;
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += "Customer Id:" + this.invoice.customer_id;
      receipt += commands.EOL;
      receipt += "Customer Name:" + this.invoice.customer_name;
      receipt += commands.EOL;
      receipt += "Customer phone:" + this.invoice.customer_phone;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
      receipt += "Date: " + this.date;
      receipt += commands.EOL;
      receipt += "Invoice created:" + this.invoice.order_placed_time;
      receipt += commands.EOL;
      // receipt += tab + tab + tab + tab;
      // receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
      // receipt += "Time: ";
      // receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
      receipt += "Item Name" + tab + '' + tab + '' + tab + '' + tab + '' + tab + "Qty" + tab + tab + "Amount"
      receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.EOL;
      this.invoice.order_data.forEach(items => {
        var tabs = '';
        if (items.menuItem.length < 25) {
          for (var i = 0; i <= (25 - items.menuItem.length); i++) {
            tabs += ' ';
          }
        } else {
          tabs = commands.EOL + tab + '' + tab + '' + tab + '' + tab;
        }
        receipt += items.menuItem + tabs + tab + items.quantity + tab + tab + '$' + items.totalPrice;
        items.additional_tax.forEach(subtax => {
          receipt += commands.EOL;
          receipt += tab + subtax.tax_title + tab + tab + tab + tab + '+' + subtax.tax_value_calculated;
        });
        receipt += commands.EOL;
        receipt += commands.HORIZONTAL_LINE.HR3_58MM;
        receipt += commands.EOL;
      });

      receipt += commands.TEXT_FORMAT.TXT_NORMAL;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
      receipt += "Sub Total:$" + this.ProductsTotal;
      receipt += commands.EOL;
      receipt += "Tax:$" + this.CartTaxDisplay;
      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR2_58MM;
      receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
      receipt += commands.EOL;
      receipt += "Total:$" + this.TotalDisplay;
      receipt += commands.EOL;
      if(this.invoice.payment_pending != '0'){
        receipt += commands.EOL;
        receipt += "Pending:$" + this.invoice.payment_pending;
        receipt += commands.EOL;
      }
      if (this.invoice.transaction_details.length != 0 && this.printTransaction == true) {
        receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
        receipt += commands.TEXT_FORMAT.TXT_NORMAL;
        receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
        receipt += "Transaction details";
        receipt += commands.EOL;
        receipt += commands.TEXT_FORMAT.TXT_NORMAL;
        receipt += commands.HORIZONTAL_LINE.HR_58MM;
        receipt += commands.EOL;

        receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
        receipt += "Method" + tab + '' + tab + '' + tab + "Date/Time" + tab + tab + tab + "Amount";
        receipt += commands.EOL;
        receipt += commands.HORIZONTAL_LINE.HR2_58MM;
        receipt += commands.EOL;
        receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
        this.invoice.transaction_details.forEach(items => {
          // var tabs = '';
          // if (items.menuItem.length < 25) {
          //   for (var i = 0; i <= (25 - items.menuItem.length); i++) {
          //     tabs += ' ';
          //   }
          // } else {
          //   tabs = commands.EOL + tab + '' + tab + '' + tab + '' + tab;
          // }
          receipt += items.payment_method + tab + tab + tab + items.payment_date + tab + tab + '$' + items.amount;

          receipt += commands.EOL;
          receipt += commands.HORIZONTAL_LINE.HR3_58MM;
          receipt += commands.EOL;
        });
      }
      //secure space on footer
      receipt += commands.EOL;
      receipt += commands.EOL;
      receipt += commands.EOL;

      this.print(this.global.printerDevice.address, receipt);
    }

  }

  financial(x) {
    console.log(x);
    if (x != 0) {
      return Number.parseFloat(x).toFixed(2);
    }
    else {
      return '0.00';
    }
  }

  total(type) {
    if (type == 'invoice') {
      this.ProductsTotal = 0;
      this.CartTax = 0;
      this.global.pos_items_discount = 0;
      var ItemDiscount = 0;
      var ItemDiscountedValue = 0;
      for (let sub of this.invoice.order_data) {
        ItemDiscount = 0;
        let CalculatedTax = 0;
        this.ProductsTotal += Number(sub.basePrice) * Number(sub.quantity);
        if (sub.discount_value) {
          sub.discount_type == 'cash' ? ItemDiscount += Number(sub.discount_value) * Number(sub.quantity) : sub.discount_type == 'perc' ? ItemDiscount += Number(sub.discount_value) / 100 * Number(sub.basePrice) * Number(sub.quantity) : console.log('discount neither cash nor percent');
          ItemDiscountedValue = Number(sub.basePrice) - Number(ItemDiscount) * Number(sub.quantity);
        } else {
          ItemDiscountedValue = Number(sub.basePrice) * Number(sub.quantity);
        }

        CalculatedTax = Number(sub.tax) / 100 * Number(ItemDiscountedValue) * Number(sub.quantity)
        this.CartTax += CalculatedTax;
        this.global.pos_items_discount += ItemDiscount;
        sub.CalculatedDiscount = this.financial(ItemDiscount);
        sub.CalculatedTax = this.financial(CalculatedTax);
      }
      this.CartTax = Math.round(this.CartTax * 100) / 100;
      this.Total = Math.round((this.ProductsTotal - this.global.pos_items_discount + this.CartTax) * 100) / 100;
      this.DiscountDisplay = this.financial(this.global.pos_items_discount);
      this.CartTaxDisplay = this.financial(this.CartTax);
      this.TotalDisplay = this.financial(this.Total);
      this.global.tax_printer = this.CartTaxDisplay;
    }
  }
}
