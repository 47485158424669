import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { map, skipUntil } from "rxjs/operators";
import { Router } from '@angular/router';

import { GlobalVariable } from '../app/global';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(public global: GlobalVariable, public http: Http, private nativeStorage: NativeStorage, public router: Router) { }

  checkStorage(): any {
    this.nativeStorage.getItem('user').then(data => {
      this.global.udid = data.udid;
      console.log("UDID auth guard", this.global.udid)
      if (this.global.udid) {
        return true;
      } else {
        return false;
      }
    }, error => {
      console.log('Udid storage', error)
    }).catch(err => { console.log(err) });
  }

  login(form: any) {
    let url = this.global.baseUrl + "Business_controller/app_login";
    var body = JSON.stringify({ username: form.username, password: form.password });

    console.log("Login API Body -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  businessInfo() {
    let url = this.global.baseUrl + "customer_controller/getbusinessinfo";
    var body = JSON.stringify({ business_username: this.global.username });

    console.log("Business Info -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  businessReview() {
    let url = this.global.baseUrl + "retail/get_business_reviews";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Business Review -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  businessStats() {
    let url = this.global.baseUrl + "retail/get_dashboard_stats";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Business Stats -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  // App settings
  businessApp_Color() {
    let url = this.global.baseUrl + "retail/get_business_app_colors";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Business App color -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  updateBusinessApp_Color(banner, specialOffer, orderNow, giftcard, myReward) {
    let url = this.global.baseUrl + "retail/update_business_app_colors";
    var body = JSON.stringify({
      business_id: this.global.udid,
      banner_color: banner,
      special_offer_color: specialOffer,
      order_now_color: orderNow,
      gift_cards_color: giftcard,
      my_rewards_color: myReward
    });

    console.log("Update Business App color -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  getBusinessApp_images() {
    let url = this.global.baseUrl + "retail/get_business_images";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Business App images -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  updateBusinessApp_Images(key, action, image) {
    let url = this.global.baseUrl + "retail/upload_business_images";
    var body = JSON.stringify({
      business_id: this.global.udid,
      action: action,
      [key]: image
    });

    console.log("Update Business App Images -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  getBusinessApp_Ads() {
    let url = this.global.baseUrl + "retail/get_business_app_ads";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Business App Ads -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  addBusinessApp_Ads(title, descript, imgURL, image) {
    let url = this.global.baseUrl + "retail/add_business_app_ad";
    var body = JSON.stringify({
      business_id: this.global.udid,
      title: title,
      description: descript,
      url: imgURL,
      image: image
    });

    console.log("Add Business App Ads -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  updateBusinessApp_Ads(id, title, descript, imgURL, image) {
    let url = this.global.baseUrl + "retail/update_business_app_ad";
    var body = JSON.stringify({
      business_id: this.global.udid,
      id: id,
      title: title,
      description: descript,
      url: imgURL,
      image: image
    });

    console.log("Update Business App Ads -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  deleteBusinessApp_Ads(id) {
    let url = this.global.baseUrl + "retail/delete_business_app_ad";
    var body = JSON.stringify({ business_id: this.global.udid, id: id });

    console.log("Delete Business App Ads -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }
  // End App settings

  logout(id, Deviceid) {
    let url = this.global.baseUrl + "Business_controller/app_logout";
    var data = JSON.stringify({ business_id: id, player_id: Deviceid });

    console.log("Logout API Body -> ");
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  //Category CRUD
  createCategory(categoryName, tax, color, priority, taxId?) {
    let url = this.global.baseUrl + "retail/create_category";
    var data = JSON.stringify({ business_id: this.global.udid, name: categoryName, tax_value: tax, priority: priority, color: color, tax_id: taxId });

    console.log("Create category -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteCategory(categoryId) {
    let url = this.global.baseUrl + "retail/del_category";
    var data = JSON.stringify({ business_id: this.global.udid, id: categoryId });

    console.log("Del category -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateCategory(categoryId, categoryName, tax, color, priority, taxId?) {
    let url = this.global.baseUrl + "retail/update_category";
    var data = JSON.stringify({ id: categoryId, name: categoryName, tax_value: tax, priority: priority, color: color, tax_id: taxId });

    console.log("update category -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getCategory() {
    let url = this.global.baseUrl + "retail/get_category";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("Get Category call -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Category CRUD End

  //Product CRUD
  getProducts(offset, limit, forced = false, id?) {
    let url = this.global.baseUrl + "Inventory_controller/get_product";
    var data = JSON.stringify({ business_id: this.global.udid, offset: offset, limit: limit, forced: forced, category_id: id });

    console.log("Search products -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  searchProduct(name, sku, categoryName) {
    let url = this.global.baseUrl + "Inventory_controller/search_product";
    var data = JSON.stringify({ business_id: this.global.udid, name: name, sku: sku, category_name: categoryName });

    console.log("Get products -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createProduct(form: any, categoryId, img, colorCode, quantity, low_count, deadItem, id = false) {
    let url = this.global.baseUrl + "Inventory_controller/create_product";
    var data = JSON.stringify({
      business_id: this.global.udid,
      category_id: categoryId,
      name: form.product_name,
      cost: form.product_cost,
      image: img,
      quantity: quantity,
      retail: form.product_retail,
      extended_retail: form.product_Ext_retail,
      sku: form.product_Sku,
      color: colorCode,
      unit_id: form.product_unitId,
      priority: form.product_priority,
      discount_type: form.discount_Type,
      discount_value: form.product_disValue,
      low_count: low_count,
      dead_item: deadItem,
      vendor_id: id
    });

    console.log("Create product -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateProduct(form: any, categoryId, productId, img, colorCode, quantity, low_count, blockItem, from, to, deadItem) {
    let url = this.global.baseUrl + "Inventory_controller/update_product";
    var data = JSON.stringify({
      business_id: this.global.udid,
      category_id: categoryId,
      id: productId,
      name: form.product_name,
      cost: form.product_cost,
      image: img,
      quantity: quantity,
      retail: form.product_retail,
      extended_retail: form.product_Ext_retail,
      sku: form.product_Sku,
      color: colorCode,
      unit_id: form.product_unitId,
      priority: form.product_priority,
      discount_type: form.discount_Type,
      discount_value: form.product_disValue,
      low_count: low_count,
      block: blockItem,
      from: from,
      to: to,
      dead_item: deadItem
    });

    console.log("Update product -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteProduct(productId) {
    let url = this.global.baseUrl + "Inventory_controller/del_product";
    var data = JSON.stringify({ business_id: this.global.udid, id: productId });

    console.log("Del product -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  historyProduct(productId) {
    let url = this.global.baseUrl + "Inventory_controller/get_product_history";
    var data = JSON.stringify({ business_id: this.global.udid, id: productId });

    console.log("history product -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Product CRUD End

  //Vendors CRUD
  getVendors() {
    let url = this.global.baseUrl + "Inventory_controller/get_vendor";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("Get vendors -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createVendor(form: any) {
    let url = this.global.baseUrl + "Inventory_controller/create_vendor";
    var data = JSON.stringify({
      business_id: this.global.udid,
      name: form.vendor_name,
      phone: form.vendor_phone,
      address: form.vendor_address,
      tax_id: form.tax_id,
      email: form.vendor_email,
      notes: form.notes

    });

    console.log("Create vendor -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateVendor(form: any, vendorId) {
    let url = this.global.baseUrl + "Inventory_controller/update_vendor";
    var data = JSON.stringify({
      id: vendorId,
      name: form.vendor_name,
      phone: form.vendor_phone,
      address: form.vendor_address,
      tax_id: form.tax_id,
      email: form.vendor_email,
      notes: form.notes
    });

    console.log("update vendor -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteVendor(vendorId) {
    let url = this.global.baseUrl + "Inventory_controller/del_vendor";
    var data = JSON.stringify({ business_id: this.global.udid, id: vendorId });

    console.log("Del vendor -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Vendor CRUD End

  //Purchases CRUD
  getPurchases(offset, limit) {
    let url = this.global.baseUrl + "Inventory_controller/get_purchase";
    var data = JSON.stringify({ business_id: this.global.udid, offset: offset, limit: limit });

    console.log("Get purchases -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createPurchase(total, type, email) {
    let url = this.global.baseUrl + "Inventory_controller/create_purchase";
    var data = JSON.stringify({
      business_id: this.global.udid,
      product: this.global.createPurArray,
      vendor: this.global.createVenArray,
      total_price: total,
      email: email,
      action: type
    });

    console.log("Create vendor -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deletePurchase(purchaseId) {
    let url = this.global.baseUrl + "Inventory_controller/del_purchase";
    var data = JSON.stringify({ business_id: this.global.udid, id: purchaseId });

    console.log("Del purchase -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Purchases CRUD End

  //Invoice CRUD
  getInvoices(offset, limit) {
    let url = this.global.baseUrl + "retail/get_orders_pos";
    var data = JSON.stringify({ id: this.global.udid, offset: offset, limit: limit });

    console.log("Get Invoices -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createInvoice(total, tax, type, email) {
    let url = this.global.baseUrl + "retail/place_order_pos_retail";
    var data = JSON.stringify({
      business_id: this.global.udid,
      username: this.global.username,
      refund: type,
      udid: this.global.createCustArray[0].id,
      payment_info: {
        address: "",
        token: ""
      },
      order_info: this.global.createInvoiceArray,
      instructions: {
        "Type": "Pickup",
        "BusinessDiscount": 0,
        "GainDiscount": 0,
        "StoreCredit": 0,
        "Tip": 0,
        "Points": 0,
        "Notes": "",
        "giftcard": "",
        "tax": tax,
        "cart_instruction": ""
      },
      total: total,
      scheduled_time: null,
      payment_type: "",
      email: email,
      pos: true
    });

    console.log("Create Invoice -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteInvoice(invoice_ids) {
    let url = this.global.baseUrl + "retail/delete_invoice";
    var data = JSON.stringify({ business_id: this.global.udid, ids: invoice_ids });

    console.log("Get Invoices -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Invoice CRUD End

  //Customers CRUD
  getCustomers(offset, limit) {
    let url = this.global.baseUrl + "customer_controller/get_search_customer";
    var data = JSON.stringify({ business_id: this.global.udid, offset: offset, limit: limit });

    console.log("Get Customer -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteCustomer(udid) {
    let url = this.global.baseUrl + "customer_controller/delete_customer";
    var data = JSON.stringify({ business_id: this.global.udid, udid: udid });

    console.log("Del customer -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteWholesale(id) {
    let url = this.global.baseUrl + "customer_controller/delete_wholesale_customer";
    var data = JSON.stringify({ business_id: this.global.udid, id: id });

    console.log("Del wholesale customer -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  SignupCustomer(form) {
    var link = this.global.baseUrl + 'Customer_controller/signup';
    var data = JSON.stringify({ business_id: this.global.udid, firstname: form.firstName, lastname: form.lastName, email: form.email, password: form.password, phone: form.phone, birthday: form.Birthday, anniversary: form.Anniversary });
    console.log("Register customer ", data);
    return this.http.post(link, data)
      .pipe(map((response: any) => response.json()));
  }

  SignupWholesale(form) {
    var link = this.global.baseUrl + 'Customer_controller/create_wholesale_customer';
    var data = JSON.stringify({ business_id: this.global.udid, business_name: form.business_name, tax_id: form.tax_id, email: form.email, account_id: form.account_id, phone: form.phone, name: form.contact_name, notes: form.notes });
    console.log("Register wholesale ", data);
    return this.http.post(link, data)
      .pipe(map((response: any) => response.json()));
  }

  UpdateCustomer(form, point) {
    var link = this.global.baseUrl + 'Customer_controller/update_profile';
    var data = JSON.stringify({ business_id: this.global.udid, firstname: form.firstName, lastname: form.lastName, email: form.email, password: form.password, phone: form.phone, birthday: form.Birthday, anniversary: form.Anniversary, points: point });
    console.log("Update customer ", data);
    return this.http.post(link, data)
      .pipe(map((response: any) => response.json()));
  }

  UpdateWholesaleCust(id, form) {
    var link = this.global.baseUrl + 'Customer_controller/update_wholesale_customer';
    var data = JSON.stringify({ business_id: this.global.udid, id: id, business_name: form.business_name, email: form.email, tax_id: form.tax_id, phone: form.phone, account_id: form.account_id, name: form.contact_name, notes: form.notes });
    console.log("Update customer ", data);
    return this.http.post(link, data)
      .pipe(map((response: any) => response.json()));
  }

  customerHistory(id) {
    let url = this.global.baseUrl + "retail/get_report_history";
    var data = JSON.stringify({ business_id: this.global.udid, udid: id });

    console.log("Get customer history -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Customers CRUD End

  //Tax CRUD
  getTaxes() {
    let url = this.global.baseUrl + "retail/get_business_taxes";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("Get taxes -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createTaxes(form) {
    let url = this.global.baseUrl + "retail/add_business_taxes";
    var data = JSON.stringify({ business_id: this.global.udid, tax_title: form.taxTitle, tax_value: form.taxValue });

    console.log("Create taxes -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateTaxes(taxId, form) {
    let url = this.global.baseUrl + "retail/update_business_taxes";
    var data = JSON.stringify({ business_id: this.global.udid, tax_id: taxId, tax_title: form.taxTitle, tax_value: form.taxValue });

    console.log("update taxes -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  delTaxes(id) {
    let url = this.global.baseUrl + "retail/delete_business_taxes";
    var data = JSON.stringify({ business_id: this.global.udid, tax_id: id });

    console.log("del taxes -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Tax CRUD End

  //Payment API's
  updateTransectionDetail(orderNum, type, total, card_info?) {
    console.log("Card Info serer ", card_info);
    let url = this.global.baseUrl + "retail/update_transaction_payment_detail";
    var data = JSON.stringify({
      business_id: this.global.udid,
      order_number: orderNum,
      payment_type: type,
      amount: total,
      card_info: {
        'card_number': card_info[0].card_number,
        'card_cvv': card_info[0].card_cvv,
        'card_expiry': card_info[0].card_expiry,
        'transection_type': card_info[0].transection_type
      }
    });

    console.log("Update transection Detail -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Payment API's End

  //Reporting start
  getReport() {
    let url = this.global.baseUrl + "retail/get_stock_report";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("Get Report -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getReportHistory(date, type) {
    let url = this.global.baseUrl + "retail/get_report_history";
    var data = JSON.stringify({ business_id: this.global.udid, date: date, report_type: type });

    console.log("Get report history -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getInventoryReport() {
    let url = this.global.baseUrl + "retail/get_inventory_report";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("Get inventory Report -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getSelectedRevenue(value) {
    let url = this.global.baseUrl + "retail/get_stock_report";
    var data = JSON.stringify({ business_id: this.global.udid, date: value });

    console.log("Get Selected Rev -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  stockReport() {
    let url = this.global.baseUrl + "retail/stock_report";
    var data = JSON.stringify({ business_id: this.global.udid, username: this.global.name });

    console.log("Stock report -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Reporting End

  // Promotion CRUD
  createPromotion(form: any, wholeStore, productList) {
    let url = this.global.baseUrl + "inventory_controller/create_promotion";
    var data = JSON.stringify({ business_id: this.global.udid, from: form.from, to: form.to, wholeStore: wholeStore, products: productList, discount_value: form.promotion_disValue, discount_type: form.discount_Type });

    console.log("Create promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updatePromotion(promotionId, form: any, wholeStore, productList) {
    let url = this.global.baseUrl + "inventory_controller/update_promotion";
    var data = JSON.stringify({ business_id: this.global.udid, promotion_id: promotionId, from: form.from, to: form.to, wholeStore: wholeStore, products: productList, discount_value: form.promotion_disValue, discount_type: form.discount_Type });

    console.log("Create promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deletePromotion(promotionId) {
    let url = this.global.baseUrl + "inventory_controller/delete_promotion";
    var data = JSON.stringify({ business_id: this.global.udid, id: promotionId });

    console.log("get promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getPromotion(offset, limit) {
    let url = this.global.baseUrl + "inventory_controller/get_promotions";
    var data = JSON.stringify({ business_id: this.global.udid, offset: offset, limit: limit });

    console.log("get promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // Promotion CRUD End

  // SMS Promotion
  getSMSData() {
    let url = this.global.baseUrl + "retail/business_messges_detail";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get SMS promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  sendSMSData(customers) {
    let url = this.global.baseUrl + "retail/send_business_sms";
    var data = JSON.stringify({ business_id: this.global.udid, customers: customers, message: this.global.message_to_users });

    console.log("send SMS promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // SMS Promotion End

  // Email Promotion
  getEmailData() {
    let url = this.global.baseUrl + "retail/business_email_detail";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get Email promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  sendEmailData(customers) {
    let url = this.global.baseUrl + "retail/send_business_email";
    var data = JSON.stringify({ business_id: this.global.udid, customers: customers, message: this.global.message_to_users, subject: this.global.emailSubject });

    console.log("send Email promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // Email Promotion End

  // Push Notification promotion
  getPushNotificationData() {
    let url = this.global.baseUrl + "retail/business_notification_detail";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get push notification promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  sendPushNotificationData(customers) {
    let url = this.global.baseUrl + "retail/send_business_notification";
    var data = JSON.stringify({ business_id: this.global.udid, customers: customers, message: this.global.message_to_users });

    console.log("send Push notification promotion -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // Push Notification promotion End

  // Giftcard design
  getGiftcardDesign() {
    let url = this.global.baseUrl + "retail/get_business_giftcard_categories";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get Giftcard design -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createGiftcardCategory(name, priority, hidden) {
    let url = this.global.baseUrl + "retail/add_business_giftcard_categories";
    var data = JSON.stringify({ business_id: this.global.udid, category_name: name, priority: priority, hidden: hidden });

    console.log("create Giftcard design -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateGiftcardCategory(id, name, priority, hidden) {
    let url = this.global.baseUrl + "retail/update_business_giftcard_categories";
    var data = JSON.stringify({ business_id: this.global.udid, id: id, category_name: name, priority: priority, hidden: hidden });

    console.log("create Giftcard design -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteGiftcardDesign(id) {
    let url = this.global.baseUrl + "retail/delete_business_giftcard_categories";
    var data = JSON.stringify({ business_id: this.global.udid, id: id });

    console.log("Del Giftcard design -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getGiftcardCategory_Images(id) {
    let url = this.global.baseUrl + "retail/business_giftcard_designs";
    var data = JSON.stringify({ business_id: this.global.udid, category_id: id });

    console.log("Get Giftcard category design -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  addGiftcardCategory_Image(id, image) {
    let url = this.global.baseUrl + "retail/add_business_giftcard_designs";
    var data = JSON.stringify({ business_id: this.global.udid, category_id: id, image: image });

    console.log("Add Giftcard category image -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateGiftcardCategory_Image(categoryId, imgId, image) {
    let url = this.global.baseUrl + "retail/update_business_giftcard_designs";
    var data = JSON.stringify({ business_id: this.global.udid, category_id: categoryId, id: imgId, image: image });

    console.log("Update Giftcard category image -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  delGiftcardCategory_Images(id) {
    let url = this.global.baseUrl + "retail/delete_business_giftcard_designs";
    var data = JSON.stringify({ business_id: this.global.udid, id: id });

    console.log("Del Giftcard category image -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // Giftcard design End

  // Giftcard Stats
  getGiftcardStats() {
    let url = this.global.baseUrl + "retail/business_giftcard_stats";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get Giftcard Stats -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  // Giftcard Stats End

  //POS Banners CRUD
  getPOSBanners() {
    let url = this.global.baseUrl + "business_controller/pos_banners_list";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get POS banners -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  addPOSBanner(img) {
    let url = this.global.baseUrl + "business_controller/add_pos_banner_image";
    var data = JSON.stringify({ business_id: this.global.udid, image: img });

    console.log("Add POS banner -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updatePOSBanner(bannerId, img) {
    let url = this.global.baseUrl + "business_controller/update_pos_banner_image";
    var data = JSON.stringify({ business_id: this.global.udid, id: bannerId, image: img });

    console.log("Update POS banner -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  delPOSBanner(bannerId) {
    let url = this.global.baseUrl + "business_controller/delete_pos_banner_image";
    var data = JSON.stringify({ business_id: this.global.udid, id: bannerId });

    console.log("Del POS banner -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //POS Banners CRUD End

  //Access Level CRUD
  getAccessLevel() {
    let url = this.global.baseUrl + "retail/get_access_levels";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get Access level -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  getPermissions() {
    let url = this.global.baseUrl + "retail/get_portal_permission";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get permissions -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  addAccessLevel(title, permissionArray) {
    let url = this.global.baseUrl + "retail/add_user_access_level";
    var data = JSON.stringify({ business_id: this.global.udid, title: title, permission: permissionArray, created_by: this.global.username });

    console.log("Add Access level -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateAccessLevel(id, title, permissionArray) {
    let url = this.global.baseUrl + "retail/update_user_access_level";
    var data = JSON.stringify({ business_id: this.global.udid, id: id, title: title, permission: permissionArray, created_by: this.global.username });

    console.log("update Access level -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  delAccessLevels(accessId) {
    let url = this.global.baseUrl + "retail/delete_user_access_level";
    var data = JSON.stringify({ business_id: this.global.udid, id: accessId });

    console.log("Del Access level -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Access Level CRUD End 

  //Business Staff CRUD
  getStaff() {
    let url = this.global.baseUrl + "retail/get_business_staff";
    var data = JSON.stringify({ business_id: this.global.udid });

    console.log("get staff -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  createStaff(form: any) {
    let url = this.global.baseUrl + "retail/add_business_staff";
    var data = JSON.stringify({
      business_id: this.global.udid,
      access_level_id: form.accessId,
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      password: form.password,
      phone: form.phone
    });

    console.log("Create staff -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  updateStaff(form: any, id) {
    let url = this.global.baseUrl + "retail/update_business_staff";
    var data = JSON.stringify({
      business_id: this.global.udid,
      access_level_id: form.accessId,
      id: id,
      first_name: form.first_name,
      last_name: form.last_name,
      password: form.password,
      phone: form.phone
    });

    console.log("Update staff -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }

  deleteStaff(id) {
    let url = this.global.baseUrl + "retail/delete_business_staff";
    var data = JSON.stringify({ business_id: this.global.udid, id: id });

    console.log("del staff -> ", data);
    return this.http.post(url, data)
      .pipe(map((response: any) => response.json()));
  }
  //Business Staff CRUD End

  //Business Profile
  retailBusinessInfo() {
    let url = this.global.baseUrl + "retail/get_business_info";
    var body = JSON.stringify({ business_id: this.global.udid });

    console.log("Get Business Info -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }

  updateBusinessProfile(generalInfoform: any, orderInfoform: any, permission, delivery_time, pickup_time, account_info) {
    let url = this.global.baseUrl + "retail/update_business_info";
    var body = JSON.stringify({
      business_id: this.global.udid,
      general_info: generalInfoform,
      permissions: permission,
      delivery_time: delivery_time,
      pickup_time: pickup_time,
      order_info: orderInfoform,
      account_info: account_info
    });

    console.log("Update Business profile -> ", body);
    return this.http.post(url, body)
      .pipe(map((response: any) => response.json()));
  }
  //Business Profile End  

  private catchError(error: Response | any) {
    console.log("error ", error);
    return Observable.throw(error.error || "Server error");
  }

  private logResponse(res: Response | any) {
    console.log("server response", res);
    // console.log("server response - parsed", res.json());
  }

  private extractData(res: Response) {
    return res.json();
  }

}
