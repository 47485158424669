import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingController, Events, NavController, ModalController, NavParams } from '@ionic/angular';

import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ServerService } from '../../app/server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-product-customer-modal',
  templateUrl: './product-customer-modal.page.html',
  styleUrls: ['./product-customer-modal.page.scss'],
})
export class ProductCustomerModalPage implements OnInit {
  @Input() pageTitle: string;
  products = [];
  productStatus: boolean = false;
  searchProd: any;
  searchItems = [];
  searchBy: any = 'sku';

  customers: any = [];
  customerStatus: boolean = false;
  searchCust: any;

  qrCode: any;
  qrItem: any;

  limit = 15;
  offset = 0;

  constructor(private route: ActivatedRoute, public events: Events, private navCtrl: NavController,
    public router: Router, public loadingCtrl: LoadingController, private server: ServerService,
    public global: GlobalVariable, public modalController: ModalController, public navParams: NavParams,
    private barcodeScanner: BarcodeScanner) {

    navParams.get('pageTitle');
  }

  ngOnInit() {
    if (this.pageTitle == 'Product') {
      this.getProducts();
    } else {
      this.getCustomers();
    }
  }

  //Search code
  async selectedSearch($event) {
    this.searchBy = $event.target.value;
    this.searchProd = "";
    this.searchItems = [];
    console.log("Search by ", this.searchBy);
  }

  async setFilteredItems(findBy) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Product ", this.searchProd);
    loading.dismiss();
    if (this.searchProd != "") {
      loading.dismiss();
      this.filterItems(this.searchProd, findBy);
      console.log("After Search ", this.searchItems);
    } else if (this.searchProd == "") {
      this.searchItems = [];
    }
  }

  filterItems(searchTerm, findBy) {
    if (findBy == 'name') {
      // return this.products.filter((item) => {
      //   return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts(searchTerm, '', '');
    }

    if (findBy == 'sku') {
      // return this.products.filter((item) => {
      //   return item.sku.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      // });
      this.searchProducts('', searchTerm, '');
    }
  }

  async searchProducts(name, sku, catName) {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.searchProduct(name, sku, catName);
    response.subscribe(data => {
      loading.dismiss();
      console.log("Data res: ", data);

      if (data.success != false) {
        console.log("Data res: ", data.data);
        for (let x = 0; x < data.data.length; x++) {
          this.searchItems.push(data.data[x]);
        }
        for (var i = 0; i <= this.searchItems.length; i++) {
          this.searchItems[i].Qty = 1;
        }
      } else {
        this.global.presentToast(data.message);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  emptySearch() {
    this.searchItems = [];
  }
  //Search End

  doInfinite(infiniteScroll) {
    console.log('Begin async operation');

    setTimeout(() => {
      if (this.pageTitle == 'Product') {
        this.getProducts();
      } else {
        this.getCustomers();
      }
      console.log('Async operation has ended');
      infiniteScroll.target.complete();
    }, 500);
  }

  increase(item) {
    if (item.Qty === NaN) {
      item.Qty = 0;
      item.Qty++;
    } else {
      item.Qty++;
      this.global.createInvoiceArray.forEach(element => {
        if (Number(element.uniqueId) == Number(item.id)) {
          if (item.Qty == 0) {
            // element.Qty++;
          } else {
            element.quantity++;
          }
        }
      });
      console.log("Global Array increase", this.global.createInvoiceArray);
    }
  }

  decrease(item) {
    if (item.Qty < 0) {
      item.Qty = 0;
    }

    if (item.Qty != 0) {
      item.Qty--;
      this.global.createInvoiceArray.forEach(element => {
        if (Number(element.uniqueId) == Number(item.id)) {
          if (item.Qty == 0) {
          } else {
            element.quantity--;
          }
        }
      });
      console.log("Global Array decrease", this.global.createInvoiceArray);
    }
  }

  addQty(item) {
    if (item.Qty < 0 || item.Qty == '' || item.Qty == null) {
      item.Qty = 0;
    }
    console.log("Changing QTY ", item)
  }

  async addToArray(item) {
    let idMatch = false;
    let cost;
    if(item.discount_type == 'cash'){
      cost = item.cost - item.discount_value;
    } else {
      cost = this.calculatePerc(item.cost, item.discount_value);
    }

    if (this.global.createInvoiceArray.length == 0) {
      cost = Number(cost) * Number(item.Qty);
      
      this.global.createInvoiceArray.push({ 
        'restId': this.global.udid,
        'uniqueId': item.id,
        'menuItem': item.name,
        'image': "",
        'quantity': item.Qty, 
        'basePrice': item.cost,
        'discount_type': item.discount_type,
        'discount_value': item.discount_value,
        'tax_type': item.tax_type,
        'tax_value': item.tax_value,
        'totalPrice': cost,
        'menuExtrasSelected': [],
        'additional_tax': item.additional_tax,
        'reward': false
      });
    } else {
      this.global.createInvoiceArray.forEach(element => {
        if (Number(element.uniqueId) == Number(item.id)) {
          idMatch = true;
        } 
      });

      if (idMatch) {
        console.log("Already present");
        this.global.createInvoiceArray.forEach(element => {
          if (Number(element.uniqueId) == Number(item.id)) {
            if (item.Qty == 0) {
              // element.Qty++;
            } else {
              item.Qty++;
              element.quantity++;
              console.log("Item QTY", item.Qty);
              console.log("Element QTY", element.quantity);
              element.totalPrice = Number(item.cost) * Number(element.quantity);
              
              console.log("Already present", element.quantity);
            }
          }
        });
      } else {
        cost = Number(cost) * Number(item.Qty);
        
        this.global.createInvoiceArray.push({ 
          'restId': this.global.udid,
          'uniqueId': item.id, 
          'menuItem': item.name, 
          'image': "",
          'quantity': item.Qty,
          'basePrice': item.cost,
          'discount_type': item.discount_type,
          'discount_value': item.discount_value,
          'tax_type': item.tax_type,
          'tax_value': item.tax_value,
          'totalPrice': cost, 
          'menuExtrasSelected': [],
          'additional_tax': item.additional_tax,
          'reward': false
        });
      }
    }
    console.log(this.global.createInvoiceArray)
    this.close();
  }

  async getProducts() {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.getProducts(this.offset, this.limit);
    response.subscribe(data => {
      loading.dismiss();

      if (data.success != false) {
        this.productStatus = data.success;
        console.log("Data res: ", data.data);
        this.offset = this.offset + this.limit;
        // this.products = data.data;
        for (let x = 0; x < data.data.length; x++) {
          this.products.push(data.data[x]);
        }

        for (var i = 0; i <= this.products.length; i++) {
          this.products[i].Qty = 1;
        }
      } else {
        this.global.presentToast(this.global.toast_wentWrong);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  async getCustomers() {
    const loading = await this.loadingCtrl.create({
      message: this.global.loader_plzWait,
      duration: 2000
    });
    await loading.present();

    let response = this.server.getCustomers(this.offset, this.limit);
    response.subscribe(data => {
      loading.dismiss();

      if (data.status != false) {
        this.customerStatus = data.status;
        console.log("Data res: ", data.data);
        // this.customers = data.data;
        for (let x = 0; x < data.data.length; x++) {
          this.customers.push(data.data[x]);
        }
        
        this.offset = this.offset + this.limit;
      } else {
        this.global.presentToast(this.global.toast_wentWrong);
      }
    }, error => {
      loading.dismiss();
      console.log(error);
      this.global.presentToast(this.global.toast_checkInternet);
    });
  }

  addCusToArray(item) {
    if (this.global.createCustArray.length == 0) {
      this.global.createCustArray.push({ 'id': item.udid, 'first_name': item.first_name, 'last_name': item.last_name, 'email': item.email });
    }

    console.log(this.global.createCustArray);
    this.close();
  }

  //barcode to list
  scanQR() {
    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      prompt: 'Place a barcode to scan product sku',
      resultDisplayDuration: 500,
      formats: 'QR_CODE,PDF_417,RSS_EXPANDED,EAN-13',
      orientation: 'portrait',
      disableSuccessBeep: false
    };

    this.barcodeScanner.scan(options).then(barcodeData => {
      console.log('Barcode data', barcodeData);
      this.global.presentToast(barcodeData.text)
      this.qrCode = barcodeData.text;
      this.qrItem = this.filterItems(this.qrCode, 'sku');
      if(this.qrItem.length == 0){
        this.global.presentToast("No product found for " + this.qrCode)
      }else{
        this.addToArray(this.qrItem[0]);
      }
    }).catch(err => {
      console.log('Error', err);
      // this.global.presentToast("Invalid barcode")
    });
  }

  //Search code start
  async selectedSearchCustomer($event) {
    this.searchBy = $event.target.value;
    this.searchCust = "";
    this.searchItems = [];
    console.log("Search by ", this.searchBy);
  }

  async setFilteredCust(findBy) {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Customer ", this.searchCust, findBy);
    loading.dismiss();
    if (this.searchCust != "") {
      loading.dismiss();
      this.searchItems = this.filterCust(this.searchCust, findBy);
      console.log("After Search ", this.searchItems);
    }
    else if (this.searchCust == "") {
      this.searchItems = [];
    }
  }

  filterCust(searchTerm, findBy) {
    if (findBy == 'udid') {
      return this.customers.filter((item) => {
        return item.udid.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      });
    }

    if (findBy == 'name') {
      return this.customers.filter((item) => {
        return item.first_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      });
    }
  }

  emptySearchCust() {
    this.searchItems = [];
  }
  //Search Code end

  close() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  calculatePerc(cost, discount){
    var value = (cost - ((discount / 100) * cost)).toFixed(2);
    // console.log("calulate per - > ", value);
    return value;
  }

}
