import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable()
export class GlobalVariable {
    udid: any;
    username: any;
    name: any;
    email: any;
    password: any;
    PhoneNo: any;
    logo: any;

    pos_items_discount = 0;
    tax_printer = 0; 
    printerDevice: any;
    noPrinter = false;
    product_last_search: any = '';
    product_searchBy: any = '';
    platformType: any;

    // baseUrl = "https://staging.onlineordering.mikronexus.com/index.php/";
    baseUrl = 'https://onlineordering.mikronexus.com/online-ordering-new/index.php/';
    // giftcardUrl = 'https://onlineordering.mikronexus.com/online-ordering-new/index.php/';

    // MenuBaseUrl = 'https://onlineordering.mikronexus.com/online-ordering-new/index.php/Menu';
    // RadeemUrl = 'https://onlineordering.mikronexus.com/online-ordering-new/index.php/Business_controller';

    //Alert messages
    alert_header = 'Are you sure';
    alert_delProduct = "you want to <strong>Delete</strong> this product !!!";
    alert_delCategory = "you want to <strong>Delete</strong> this category !!!";
    alert_delVendor = "you want to <strong>Delete</strong> this vendor !!!";
    alert_delPurchase = "you want to <strong>Delete</strong> this purchase !!!";
    alert_delPromotion = "you want to <strong>Delete</strong> this promotion !!!";
    alert_delCustomer = "you want to <strong>Delete</strong> this customer !!!";
    alert_delTax = "you want to <strong>Delete</strong> this tax information !!!";
    alert_delStaff = "you want to <strong>Delete</strong> this Staff member !!!";
    alert_delPOSBanner = "you want to <strong>Delete</strong> this Banner !!!";
    alert_delAccessLevel = "you want to <strong>Delete</strong> this Access Level !!!";
    alert_smsPromo = "you want to <strong>SEND SMS</strong> to users !!!";
    alert_emailPromo = "you want to <strong>SEND Email</strong> to users !!!";
    alert_pushNotifyPromo = "you want to <strong>SEND Push Notification</strong> to users !!!";
    alert_delGiftcardCategory = "you want to <strong>Delete</strong> Gift card Category !!!";
    alert_delGiftcardBanner = "you want to <strong>Delete</strong> this Giftcard Banner !!!";
    alert_delInvoices = "you want to <strong>Delete selected Invoice</strong> !!!";
    alert_delsingleInvoice = "you want to <strong>Delete Invoice</strong> !!!";
    alert_delApp_Ad = "you want to <strong>Delete</strong> this App Ad !!!";
    //

    //Toast messages
    toast_wentWrong = "Something went wrong";
    toast_checkInternet = "Something went wrong check your internet connection.";
    toast_formValues = "Some values were not given or were incorrect, please fill them";
    //

    //Loader messages
    loader_plzWait = "Please wait..."
    //

    //Printer
    errorConnecting = "There was an error connecting to the printer, please try again!";
    errorPrinting = "There was an error printing, please try again!" ;
    printSuccess = "Successful print!";
    errorActivating = 'Error activating bluetooth, please try again!';

    //Draft Message
    draftSaved = "Draft saved";
    draftNotSaved = "Draft not saved";

    //create purchase 
    createPurArray = [];
    createVenArray = [];

    //create invoice
    createInvoiceArray = [];
    createCustArray = [];

    //promotional products
    onSaleProducts = [];

    //promotion
    message_to_users: any = '';
    emailSubject: any = '';

    //Draft
    draftPurArray = [];
    draftInvoiceArray = [];

    //Report detail
    reportDetails: any;

    //Access level
    updateAccess = [];

    constructor(public alertCtrl: AlertController, public toastCtrl: ToastController) {

    }

    async presentAlert(title, msg) {
        const alert = await this.alertCtrl.create({
            header: 'Alert',
            subHeader: title,
            message: msg,
            buttons: ['OK']
        });

        await alert.present();
    }

    async presentToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }
}